import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from '~/reportWebVitals';
import { CookiesProvider } from 'react-cookie';
import { isMobile } from 'react-device-detect';

import GlobalStyles from './components/GlobalStyles';
import AppProvider from './Context/appContext';
import LoadingPage from './pages/Loading/LoadingPage';
// import App from '~/App';

const AppPage = lazy(() => import('~/App'));
const MobileApp = lazy(() => import('~/Mobile'));

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <GlobalStyles>
        <CookiesProvider defaultSetOptions={{ path: '/' }}>
            <AppProvider>
                <Suspense fallback={<LoadingPage />}>{isMobile ? <MobileApp /> : <AppPage />}</Suspense>
            </AppProvider>
        </CookiesProvider>
    </GlobalStyles>,
);
