import React, { Children, createContext, useState } from 'react';

export const AppContext = createContext();

const AppProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const updateLoading = (status) => {
        setLoading(status);
    };
    const [username, setUsername] = useState('');
    const updateUsername = (username) => {
        setUsername(username);
    };
    const [balance, setBalance] = useState(0);
    const updateBalance = (balance) => {
        setBalance(balance);
    };
    const [LoggedInfo, setLoggedInfo] = useState({});
    const updatesetLoggedInfo = (data) => {
        setLoggedInfo(data);
    };
    const [display, setDisplay] = useState(false);
    const updateDisplay = (bool) => {
        setDisplay(bool);
    };
    const [isShowing, setIsShowing] = useState(false);
    const updateShowingPopup = (bool) => {
        setIsShowing(bool);
    };
    const [isHide, setIsHide] = useState(false);
    const updateHidePopup = (bool) => {
        setIsHide(bool);
    };
    const [Dialog, setDialog] = useState(null);
    const updateDialog = (name) => {
        setDialog(name);
    };
    const [isComfirm, setisComfirm] = useState(true);
    const updateComfirm = (bool) => {
        setisComfirm(bool);
    };
    const [isCollapse, setCollapse] = useState(true);
    const updateCollapse = (bool) => {
        setCollapse(bool);
    };
    const [isCollapsePMenu, setCollapsePMenu] = useState(false);
    const updateCollapsePMenu = (bool) => {
        setCollapsePMenu(bool);
    };
    const [ChoseGame, setChoseGame] = useState({});
    const updateChoseGame = (data) => {
        setChoseGame(data);
    };
    const [isMobile, setisMobile] = useState(false);
    const updateIsMobile = (bool) => {
        setisMobile(bool);
    };
    const [listServices, setListServices] = useState([]);
    const updateListServices = (data) => {
        setListServices(data);
    };

    const [TotalBalance, setTotalBalance] = useState(0);
    const updateTotalBalance = (data) => {
        setTotalBalance(data);
    };

    const [refreshPoint, setRefreshPoint] = useState(false);
    const updateRefreshPoint = (bool) => {
        setRefreshPoint(bool);
    };

    const [UserMember, setUserMember] = useState({});
    const updateUserMember = (data) => {
        setUserMember(data);
    };

    const [SelectFrom, setSelectFrom] = useState('TK Chính');
    const updateSelectFrom = (data) => {
        setSelectFrom(data);
    };

    const [SelectTo, setSelectTo] = useState(null);
    const updateSelectTo = (data) => {
        setSelectTo(data);
    };

    return (
        <AppContext.Provider
            value={{
                loading,
                updateLoading,
                username,
                updateUsername,
                balance,
                updateBalance,
                display,
                updateDisplay,
                isShowing,
                updateShowingPopup,
                Dialog,
                updateDialog,
                isCollapse,
                updateCollapse,
                isComfirm,
                updateComfirm,
                LoggedInfo,
                updatesetLoggedInfo,
                ChoseGame,
                updateChoseGame,
                isMobile,
                updateIsMobile,
                listServices,
                updateListServices,
                TotalBalance,
                updateTotalBalance,
                isHide,
                updateHidePopup,
                UserMember,
                updateUserMember,
                isCollapsePMenu,
                updateCollapsePMenu,
                refreshPoint,
                updateRefreshPoint,
                SelectFrom,
                updateSelectFrom,
                SelectTo,
                updateSelectTo,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;
